<template>
    <div id="app">
        <div class="container" id="top" ref="top" tabindex="-1">
            <div v-if="showSocials" class="row">
                <div class="col-12 text-right meta mb-3">
                    <Socials />
                </div>
            </div>
            <router-view/>
        </div>

        <footer v-if="showFooter" class="px-2">
            <div class="row">
                <div class="col-12 text-center">
                    <b-btn variant="link" v-scroll-to="'#top'" @click="$refs.top.focus()">
                        <b-icon icon="arrow-bar-up" font-scale="3" title="Nach oben" class="to-top" />
                    </b-btn>
                </div>
            </div>

            <p class="text-center u-bold mt-4">GitHub & Linktree Icon erstellt von <a href="https://iconmonstr.com/" title="iconmonstr" target="_blank" rel="noopener">iconmonstr</a></p>
        </footer>

        <Navigation v-if="showNavigation" />
    </div>
</template>

<script>
import Navigation from './components/Navigation'
import Socials from './components/Socials'

export default {
    name: 'App',

    components: { Socials, Navigation },

    computed: {
        showSocials () {
            return !this.$route.meta?.hideSocials
        },

        showFooter () {
            return !this.$route.meta?.hideFooter
        },

        showNavigation () {
            return !this.$route.meta?.hideNavigation
        },
    },
}
</script>

<style lang="scss">
    @import "./assets/css/app";

    a {
        text-decoration: underline;
    }
</style>
