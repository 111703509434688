<template>
    <div class="socials">
        <a href="https://nickhatboecker.de/linktree" class="h6 mr-4" target="_blank" rel="noopener" title="Gehe zu meinem Linktree">
            <img class="socials__item" src="../assets/linktree.svg" alt="Linktree Icon" width="30px" height="30px" />
        </a>
        <a href="https://bsky.app/profile/nickhatboecker.de" class="h6 mr-4" target="_blank" rel="noopener" title="Gehe zu meinem Bluesky Profil">
            <img class="socials__item" src="../assets/bluesky.svg" alt="Bluesky Logo" width="30px" height="30px" />
        </a>
        <a href="https://github.com/NickHatBoecker" class="h6 mr-4" target="_blank" rel="noopener" title="Gehe zu meinem GitHub Profil">
            <img class="socials__item" src="../assets/github.svg" alt="GitHub Logo" width="30px" height="30px" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'Socials',
}
</script>
